import React from 'react';
import { graphql } from 'gatsby';

import { Layout, PostCard } from '../components/shared';
import { MetaData } from '../components/shared/meta';
import AuthorBanner from '../components/authorPage/AuthorBanner';
import { IPostNodeProps } from './post';
import { FixedObject } from 'gatsby-image';
import { CEO, CTO } from '../utils/authorConfig';

export const pageQuery = graphql`
  query GhostAuthorQuery($slug: String!, $limit: Int!, $skip: Int!) {
    ghostAuthor(slug: { eq: $slug }) {
      ...GhostAuthorFields
      profileImageSharp {
        childImageSharp {
          avatar: fixed(width: 60, height: 60) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    allGhostPost(
      sort: { order: DESC, fields: [published_at] }
      filter: { authors: { elemMatch: { slug: { eq: $slug } } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...GhostPostFields
          featureImageSharp {
            childImageSharp {
              full: fluid(maxWidth: 558, maxHeight: 400) {
                ...GatsbyImageSharpFluid
              }
              thumb: fixed(width: 265, height: 298) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }
`;

export interface IAuthorProps {
  name?: string;
  cover_image?: string;
  profile_image?: string;
  website?: string;
  bio?: string;
  location?: string;
  facebook?: string;
  twitter?: string;
  slug?: string;
  profileImageSharp?: {
    childImageSharp: {
      avatar: FixedObject;
    };
  };
}

export interface IAuthorTemplateProps {
  data: {
    ghostAuthor: IAuthorProps;
    allGhostPost: {
      edges: IPostNodeProps[];
    };
  };
  location: {
    pathname: string;
  };
}

const Author = ({ data, location }: IAuthorTemplateProps) => {
  const author = data.ghostAuthor;
  const posts = data?.allGhostPost?.edges || [];
  const primaryColour =
    data.ghostAuthor.slug === CEO
      ? 'border-pink-500 text-pink-500'
      : data.ghostAuthor.slug === CTO
      ? 'border-paleBlue-500 text-paleBlue-500'
      : 'border-white text-white';

  return (
    <>
      <MetaData data={data} location={location} />
      <Layout>
        <div className="max-w-lg w-full">
          <AuthorBanner author={author} />
          <section className="w-full mx-auto mt-12 md:mt-8 flex justify-center flex-wrap">
            {posts.map(({ node }: IPostNodeProps) => (
              <PostCard
                primaryColour={primaryColour}
                key={node.id}
                post={node}
              />
            ))}
          </section>
        </div>
      </Layout>
    </>
  );
};

export default Author;
