import React from 'react';

import { IAuthorProps } from '../../templates/author';
import IconProfile from '../../svgs/Iconprofile';
import { graphql, useStaticQuery } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import { CTO, CEO } from '../../utils/authorConfig';

interface IAuthorBannerProps {
  author: IAuthorProps;
}

export const AUTHOR_BANNER_IMAGE_QUERY = graphql`
  query {
    ceo: file(base: { eq: "ceo.jpg" }) {
      childImageSharp {
        full: fluid(maxWidth: 558, maxHeight: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cto: file(base: { eq: "cto.jpg" }) {
      childImageSharp {
        full: fluid(maxWidth: 558, maxHeight: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fallback: file(base: { eq: "fallback.jpg" }) {
      childImageSharp {
        full: fluid(maxWidth: 558, maxHeight: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const AuthorBanner = ({ author }: IAuthorBannerProps) => {
  const image = useStaticQuery(AUTHOR_BANNER_IMAGE_QUERY);
  const isCEO = author?.slug === CEO;
  const isCTO = author?.slug === CTO;
  const title = isCEO ? 'CEO, Yozobi' : isCTO ? 'CTO, Yozobi' : 'Yozobi';
  const ceoBannerImage = image.ceo.childImageSharp.full;
  const ctoBannerImage = image.cto.childImageSharp.full;
  const fallbackImage = image.fallback.childImageSharp.full;
  const bannerImage = isCEO
    ? ceoBannerImage
    : isCTO
    ? ctoBannerImage
    : fallbackImage;

  return (
    <header className="flex rounded-lg overflow-hidden">
      <div className="flex w-1/2 md:hidden">
        <GatsbyImage fixed={bannerImage} className="w-full" alt="" />
      </div>
      <div
        className={`text-purple-900 p-12 sm:p-6 w-1/2 md:w-full sm:text-center ${
          isCEO ? 'bg-pink-500' : isCTO ? 'bg-paleBlue-500' : 'bg-white'
        }`}
      >
        <div className="font-semibold text-2xl flex items-center mb-10 sm:flex-col">
          {author.profileImageSharp ? (
            <GatsbyImage
              className="mr-8 sm:mr-0 rounded-full w-16 flex-shrink-0"
              alt={author.name}
              fixed={author.profileImageSharp.childImageSharp.avatar}
            />
          ) : (
            <div className="mr-8 sm:mr-0 rounded-full w-16 flex-shrink-0">
              <IconProfile />
            </div>
          )}
          <h1 className="sm:mt-4">{`${author.name}, ${title}`}</h1>
        </div>
        <h2 className="font-bold uppercase text-2xl mb-4">About</h2>
        <div className="italic text-xl max-w-postCardLg">
          {author.bio ? author.bio : 'Please write a small bio about yourself'}
        </div>
      </div>
    </header>
  );
};

export default AuthorBanner;
